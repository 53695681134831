import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Layout } from '../components';
import ThankYouImage from '../images/thank-you.svg';
import './404.scss';

class ThankYou extends Component {
  render() {
    const { location } = this.props;
    return (
      <Layout location={location}>
        <div className="wrapper not-found-wrap">
          <img className="not-found-image" src={ThankYouImage} alt="Thank you" />
          <div className="not-found-text">
            <h2>Thank You. We will be in touch soon</h2>
            <Link className="button" to="/">
              Go back home
            </Link>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ThankYou;
